.news-item {
    position: relative;
    h2, p {
        text-align: center;
    }
} 
.nieuws-item {
    text-align: center;
}

.news-item__img-container {
    position: relative;
    padding-bottom: 66.666%;
    overflow: hidden;
    margin-bottom: $gutter;
    
    img {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        margin: 0;
    }

    .date {
        position: absolute;
        left: 0;
        bottom: 0;
    }
}

@media screen and (min-width: $view-medium-devices) {

    .news-item {

        h2 {
            padding-left: $gutter;
            padding-right: $gutter; 
        }
    }
}
