.btn-primary {
	font-family: $font-primary-bold;
	text-transform: uppercase;
	font-size: 1.4em;
	text-decoration: none;
	border: 3px solid $color-primary;
	background-color: #fff;
	padding:0px 40px;
	color: $color-primary;
	text-decoration: none;

	&:focus,
	&:hover {
		border-color: $color-secondary;
		color: $color-secondary;
		background-color: #fff;
		text-decoration: none;
	}
}

.btn-secondary {
	text-decoration: underline;
	text-transform: uppercase;
	color: $color-primary;
	margin-bottom: $gutter/2;

	&:hover {
		text-decoration: none;
	}
}

.btn-toggle {
	width: 60px;
	// height: 60px;
	position: relative;
	background-color: $color-primary;
	margin: 0;
	display: inline-block;

	input {
		display: none;
	}
	
	.icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		fill: #fff;
		transition: all $time-fast ease;
		&:hover {
			fill: #fff;
		}
	}

	.icon--on {
		visibility: hidden;
		opacity: 0;
	}

	.icon--off {
		opacity: 1;
		visibility: visible;
	}

	input[type='checkbox']:checked ~ .icon--on {
		opacity: 1;
		visibility: visible;
	} 

	input[type='checkbox']:checked ~ .icon--off {
		visibility: hidden;
		opacity: 0;
	} 
}
