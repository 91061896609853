.date {
    background-color: $color-secondary;
    font-family: $font-primary-bold;
    color: #fff;
    width: 60px;
    height: 60px;
    display: table;

    .date__number {
        font-size: 1.3em;
        display: table-cell;
        text-align: center;
        vertical-align: middle;
    }

    .date__month {
        font-size: 0.8em;
        display: block;
    }
}