.content-page {
  text-align: center;
  padding-top: 55px;
  h1 {
    margin-bottom: $gutter;
  }

  // news item
  .nieuws-item {
    max-width: 450px;
    margin: 0 auto;
  }

  // agenda
  .agenda-item__toggle {
    pointer-events: none;
  }
}

