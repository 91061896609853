.scroll-container {
    margin: auto;
    height: 70vh;
    width: 80%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.scroll-container::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}
.scroll-container::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.scroll-container::-webkit-scrollbar-thumb {
  background: $color-primary;
  border: 43px none #ffffff;
  border-radius: 0px;
}
.scroll-container::-webkit-scrollbar-thumb:hover {
  background: $color-primary;
}
.scroll-container::-webkit-scrollbar-thumb:active {
  background: $color-primary;
}
.scroll-container::-webkit-scrollbar-track {
  background: #fffffe;
  border: 2px solid $color-primary;
  border-radius: 0px;
}
.scroll-container::-webkit-scrollbar-track:hover {
  background: #fffffe;
}
.scroll-container::-webkit-scrollbar-track:active {
  background: #ffffff;
}
.scroll-container::-webkit-scrollbar-corner {
  background: transparent;
}




