.wrapper-sections{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.wrapper-doe-mee{
    width: 80%;
    margin-top: 6rem;
    font-size: 3vw;
}

.titel-doe-mee{
    font-family: $font-primary-bold;
    font-size: 1.5em;
}

.wrapper-doe-mee h3 , h2{
    
    text-align: center;
    width: 100%;
}

.wrapper-doe-mee h3{
    margin-top: 2rem;
}

.wrapper-doe-mee h2{
    color: $color-secondary;
}

@media screen and (min-width: $view-small-devices){
.wrapper-sections{
  //  background-color: aqua;
}

.wrapper-doe-mee{
    font-size: 2.5vw;
}
}

@media screen and (min-width: $view-medium-devices){
    .wrapper-sections{
   // background-color: red;
    }

    .wrapper-doe-mee{
        font-size: 2vw;
    }
    } 

@media screen and (min-width: $view-large-devices){
    .wrapper-sections{
       // background-color: green;
    }
    .wrapper-doe-mee{
        font-size: 1.5vw;
    }
}

@media screen and (min-width: 1400px){

    .wrapper-doe-mee{
        font-size: 1.3vw;
    } 
}