.wrapper-sections {
    height: 100%;
}

.title_page {
    position: absolute;
    left: $gutter;
    top: $gutter;
}

.wrapper_text {
    font-family: $font-secondary;
    width: 80%;
    font-size: 3vw;
    //background-color: gray;
    margin-top: 6rem;
}

#intro_text {
    font-family: $font-secondary-bold;
}

.nav_activiteiten {
    margin: auto;
    margin-top: 25px;
    text-align: center;
}

.nav_activiteiten img {
    // width: 50px;
    height: 50px;
    margin-right: 10px;
}

.nav_activiteiten li {
    display: inline-block;
    list-style-type: none;
}


//576px
@media screen and (min-width: $view-small-devices) {
    .wrapper_text {
        font-size: 2.5vw;
        text-align: center;
        // background-color: palegreen;
    }

    .nav_activiteiten img {
        margin-right: 20px;
    }
}

//768
@media screen and (min-width: $view-medium-devices) {
    .wrapper_text {
        //background-color: burlywood;
        font-size: 2vw;
    }
}

@media screen and (min-width: $view-large-devices) {
    .wrapper_text {
        //background-color: brown;
        font-size: 1.2vw
    }

    .nav_activiteiten img {
        margin-right: 20px;
    }
}