.content-contact {
    margin: auto;
    margin-top: 4rem;

}

.intro {

    p {
        margin: auto;
        margin-bottom: 0;
        margin-top: 0;
        font-family: $font-secondary-bold;
    }
}

form {
    width: 65%;
    margin: auto;
}

.flex-container-form {
    margin-bottom: 1.5rem;
}

.textInput,
#textarea {
    width: 100%;
    border-style: solid;
    border-width: 3px;
    border-color: $color-primary;
    color: $color-primary;
}

#textarea {
    resize: none;
}

#verzendenInput {
    width: 100%;
    text-align: center;
    border-style: solid;
    border-width: 3px;
    border-color: $color-primary;
    margin-bottom: 15px;
}

.flex-item {
    padding: 5px;
    width: 100px;
    height: 100px;
    margin: 10px;
    list-style: none;
}

.flex-item image {
    width: 100px;
    display: none;
}

// FOOTER

footer {
    margin: 0;
    width: 100%;
    background-color: $color-secondary;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding-bottom: 15px;
}

.footer-static {
    // position: static;
}

footer img {
    width: 100px;
    height: 100px;
    padding-top: 2rem;
    margin-left: 2rem;
    margin-bottom: 2rem;
}

.tekstFooter {
    margin-left: 2rem;
    margin-bottom: 0.4rem;
    margin-top: 0;
    text-align: left;
    color: #ffffff;
    width: fit-content;
}

.footer__credits {
    color: #fff;
    font-size: 0.75rem;
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
}

p:last-of-type {
    margin-top: 0;
    padding-bottom: 10px;
}

@media screen and (min-width: $view-large-devices) {
    .content-contact {
        width: 70%;

    }

    .intro {
        display: block;
        margin-top: 4rem;
        margin-bottom: 2rem;
    }

    .intro p {
        margin-bottom: 0;

    }

    p:last-of-type {
        margin-top: 0;
        margin-bottom: 2rem;
    }

    .flex-container-form,
    .infoFooter {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            margin: 0!important;
            padding: 0;
        }
    }

    .flex-container-form {
        margin-top: 2rem;
    }

    .flex-container {
        margin-top: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .textInput {
        flex-basis: 30%;
    }

    .infoFooter {
        width: 70%;
        margin: auto;
    }

    p:last-of-type {
        margin-bottom: 0.4rem;
    }

    footer img {
        width: 100px;
        height: 85px;
        padding-top: 0;
        margin-left: 0;
        margin-bottom: 0;
    }

    footer {
        padding-top: 20px;
        padding-bottom: 20px;
    }

}