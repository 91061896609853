.icon {
    display: inline-block;
    vertical-align: middle;
    height: 20px;
    width: 20px;
    fill: $color-primary;
    transition: fill $time-fast ease;

    &:hover {
        fill: $color-secondary;
    }
}

