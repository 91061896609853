.collapse {
	display: none;
}
  
.collapse.show {
	display: block;
}

.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	transition: height 0.35s ease;
}
