// ========================================================
// GRID
// ========================================================
[class*='grid--col'] {
    display: flex;
    margin: 0;
    flex-wrap: wrap;
}

[class*='grid--col']>* {
    margin: 0;
    width: 100%;
}

.grid--col-max-3>* {
    max-width: 50%;
    flex-basis: 50%;
}

.grid-item {
    padding: $gutter/3;
}

.margin-fix {
    margin: 0 -$gutter/3;
}

// ========================================================
// TABLET
// ========================================================
@include respond-min-width($view-medium-devices) {
    .grid--col-max-3>* {
        max-width: 33.333%;
        flex-basis: 33.333%;
    }
}