.flex-carousel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    margin: auto;
    margin-top: 8rem;
}

.nieuws-wrapper {
    width: 80%;
}

.nieuws-item {
    margin-left: 10px;
}

.nieuws-item img {
    width: 100%;
    margin: auto;
}

.forward {
    margin-right: 5%;
    font-size: 2em;

}

.back {
    margin-left: 5%;
    font-size: 2em;
}

.nieuws-item h4 {
    margin-top: 7%;
    text-align: center;
}

.nieuws-item p {
    padding-top: 0.5rem;
    width: 100%;
}

.slick-disabled {
    opacity: 0.5;
}