#wrapper_page1{
    width: 100%;
    height: 100%;
   background-color: $color-primary;
   position: relative;

}

#big_logo {
    width: 175px;
    height: 175px;
    margin: auto;
    position: relative;
    top: 20%;
    
}

          .title_logo{
    color: #fff;
    position: relative;
    top: 25%;
    text-align: center;
}

.lees_meer{
    width: 200px;
    height: 50px;
    color: #fff;
    position: relative;
    top: 35%;
    margin: auto;
    text-align: center;
    border: #fff;
    border-style: solid;
    border-width: 3px;
    padding-top: 10px;

    a {
        color: #fff;
    }

    a:hover{
        color: $color-secondary;
    }
    
} 

.sponors{
    width: 15%;
    position: absolute;
    bottom: 10px;
    left: 10px;


    img{
        width: 100px;
    }
}

.dropdown_arrow{
    width: 30px;
    height: 30px;
    fill: #fff;
    position: absolute;
    left: $gutter;
    bottom: $gutter;
}

.cube{
    width: 30px;
    height: 30px;
    fill: #fff;
    position: absolute;
    right: $gutter;
    bottom: $gutter;  
}

@media screen and (min-width: $view-large-devices){
    #big_logo{
        width: 275px;
        height: 275px;
        
    } 
    .dropdown_arrow{
        width: 50px;
        height: 50px;
        fill: #fff;
        position: absolute;
        left: $gutter;
        bottom: $gutter;
    }

}